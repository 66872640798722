
import { defineComponent } from "vue";
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";
import AppSplash from "@/AppSplash.vue";
import AppTopBar from "@/AppTopbar.vue";
import AppFooter from "@/AppFooter.vue";
export default defineComponent({
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { settings, user };
  },
  components: {
    SplashScreen: AppSplash,
    AppTopBar: AppTopBar,
    AppFooter: AppFooter,
  },
  computed: {
    contentClass() {
      let party = "";
      if (this.settings.party) {
        party = "party ";
      }
      if (this.settings.isLoggedIn && !this.settings.isLoggingOut) {
        return party + "content with-menu";
      }
      return party + "content";
    },
  },
});
