<template>
  <div>
    <p>
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  inject: ["dialogRef"],
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    this.message = this.dialogRef.data.message;
  },
};
</script>
