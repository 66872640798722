
import { defineComponent } from "vue";
import HomeDefault from "@/components/HomeDefault.vue"; // @ is an alias to /src
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";
import HomeWithAuth from "@/components/HomeWithAuth.vue";

export default defineComponent({
  name: "HomeView",
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { settings, user };
  },
  components: {
    HomeDefault,
    HomeWithAuth,
  },
});
