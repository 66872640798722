<template>
  <App />
  <Toast
    position="bottom-left"
    :breakpoints="{ '600px': { width: '100%', right: '0', left: '0' } }"
  />
  <ConfirmDialog />
  <ConfirmDialog group="addVK">
    <template #message="slotProps">
      <AddVKDialog :slotProps="slotProps" />
    </template>
  </ConfirmDialog>
  <DynamicDialog />
</template>

<script>
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";
import EventBus from "@/AppEventBus";
import App from "@/App";
import AuthService from "@/services/auth.service";
import AddVKDialog from "@/components/AddVKDialog.vue";
const themes = {
  dark: "lara-dark-blue",
  light: "lara-light-blue",
};
export default {
  routeListener: null,
  themeChangeListener: null,
  responseErrorListener: null,
  loginListener: null,
  logoutListener: null,
  partyListener: null,
  refreshInterval: null,
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { user, settings };
  },
  mounted() {
    this.routeListener = (event) => {
      this.$router.push(event.route);
    };
    this.themeChangeListener = (event) => {
      const elementId = "theme-link";
      const linkElement = document.getElementById(elementId);

      if (event.init && this.settings.theme === "light") {
        return;
      }

      const cloneLinkElement = linkElement.cloneNode(true);
      const replaced = event.init ? themes.light : themes[this.settings.theme];
      const newThemeUrl = linkElement
        .getAttribute("href")
        .replace(replaced, themes[event.theme]);

      cloneLinkElement.setAttribute("id", elementId + "-clone");
      cloneLinkElement.setAttribute("href", newThemeUrl);
      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", elementId);
        this.settings.setTheme(event.theme);
      });
      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );
    };
    this.responseErrorListener = (event) => {
      if (event.response) {
        switch (event.response.status) {
          case 401:
            this.$toast.add({
              severity: "error",
              summary: "Ошибка авторизации",
              life: 3000,
            });
            EventBus.emit("logout");
            break;
          case 418:
            EventBus.emit("party", {
              toast: this.$toast,
              response: event.response.data,
            });
            break;
          default:
            this.$toast.add({
              severity: "error",
              summary: "Ошибка связи с сервером",
              detail: `Код ошибки: ${event.response.status}`,
              life: 5000,
            });
            //alert(event.response.status);
            break;
        }
      } else if (event.serverMessage) {
        this.$toast.add({
          severity: "error",
          summary: event.serverMessage,
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Ошибка связи с сервером",
          detail: `Код ошибки: ${event.message}`,
          life: 5000,
        });
      }
    };
    this.logoutListener = () => {
      AuthService.logout();
      this.user.logout();
      this.settings.isLoggingOut = true;
      setTimeout(() => {
        this.settings.isLoggedIn = false;
        this.settings.isLoggingOut = true;
      }, 500);
      this.$router.replace("/");
      clearInterval(this.refreshInterval);
      setTimeout(() => {
        this.$toast.add({
          severity: "info",
          summary: "Вы вышли из аккаунта",
          life: 3000,
        });
      }, 200);
    };
    this.loginListener = async (event) => {
      await this.user.setToken(event.token, true);
      this.settings.isLoggedIn = true;
      setTimeout(() => {
        this.$toast.add({
          severity: "success",
          summary: "Успешная авторизация",
          life: 3000,
        });
      }, 2000);
      this.refreshInterval = setInterval(async () => {
        const data = await AuthService.refreshToken(this.user.id);
        if (data && this.user.token !== data.token) {
          this.user.setToken(data.token, true);
        }
      }, 60000);
    };
    this.reloadListener = () => {
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(async () => {
        const data = await AuthService.refreshToken(this.user.id);
        if (data && this.user.token !== data.token) {
          this.user.setToken(data.token, true);
        }
      }, 60000);
    };
    this.partyListener = (event) => {
      this.settings.party = true;
      setInterval(() => {
        event.toast.add({
          severity: "warn",
          summary: event.response,
          closable: false,
          life: 6000,
        });
      }, 1100);
    };

    EventBus.on("route", this.routeListener);
    EventBus.on("login", this.loginListener);
    EventBus.on("reload", this.reloadListener);
    EventBus.on("logout", this.logoutListener);
    EventBus.on("responseError", this.responseErrorListener);
    EventBus.on("theme-change", this.themeChangeListener);
    EventBus.on("party", this.partyListener);

    EventBus.emit("theme-change", {
      theme: this.settings.theme,
      init: true,
    });
    if (this.settings.isLoggedIn) {
      setTimeout(async () => {
        await this.user.loadLocalData();
        this.refreshInterval = setInterval(() => {
          AuthService.refreshToken(this.user.id);
        }, 60000);
      });
    }
  },
  beforeUnmount() {
    EventBus.off("route", this.routeListener);
    EventBus.off("theme-change", this.themeChangeListener);
    EventBus.off("login", this.loginListener);
    EventBus.off("logout", this.logoutListener);
    EventBus.off("responseError", this.responseErrorListener);
    EventBus.off("party", this.partyListener);
    clearInterval(this.refreshInterval);
  },
  components: {
    App: App,
    AddVKDialog: AddVKDialog,
  },
};
</script>
