import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeDefault = _resolveComponent("HomeDefault")!
  const _component_HomeWithAuth = _resolveComponent("HomeWithAuth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.settings.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_HomeDefault, {
          key: 0,
          msg: "Welcome to Your Vue.js + TypeScript App"
        }))
      : _createCommentVNode("", true),
    (_ctx.settings.isLoggedIn)
      ? (_openBlock(), _createBlock(_component_HomeWithAuth, {
          key: 1,
          msg: "Авторизован"
        }))
      : _createCommentVNode("", true)
  ], 64))
}