import axios from "axios";
import EventBus from "@/AppEventBus";

const API_URL = `${process.env.VUE_APP_BACKEND_URL || ":3300"}/api/public/`;

class PublicService {
  getGlobalStats() {
    console.log(API_URL);
    return axios
      .get(API_URL + "globalStats")
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        EventBus.emit("responseError", e);
        return null;
      });
  }
}

export default new PublicService();
