
import { defineComponent } from "vue";
import PublicService from "@/services/public.service";

export default defineComponent({
  name: "HomeDefault",
  props: {
    msg: String,
  },
  data() {
    const random = Math.random() * 100;
    const random2 = Math.random() * random;
    return {
      usersCount: Math.round(random),
      activeUsersCount: Math.round(random2),
      activeFunctionsCount: Math.round(
        Math.random() * random2 * Math.random() * 4
      ),
    };
  },
  mounted() {
    PublicService.getGlobalStats().then((stats) => {
      if (stats) {
        const { usersCount, activeUsersCount, activeFunctionsCount } = stats;
        this.usersCount = usersCount;
        this.activeUsersCount = activeUsersCount;
        this.activeFunctionsCount = activeFunctionsCount;
      }
    });
  },
});
