<template>
  <AppMenu :modal="true" />
  <div>
    <div class="appbar fixed h-4rem pl-3 w-full flex flex-row">
      <div
        v-if="settings.isLoggedIn"
        class="mobile-menu flex justify-content-start align-content-center"
        :class="{
          'logout-scale': settings.isLoggingOut,
          'flex-grow-1': !settings.isLoggingOut,
        }"
      >
        <a
          class="mobile-menu-button"
          :class="{
            'open-menu': mobileMenu,
            'logout-scale': settings.isLoggingOut,
          }"
          @click="openMobileMenu()"
        >
          <span></span>
        </a>
      </div>
      <router-link to="/">
        <img :src="logo" class="pt-3" alt="KIOW" width="210" />
      </router-link>
      <div
        class="flex flex-grow-1 justify-content-end align-content-center pr-2 pt-2"
      >
        <Button
          icon="pi pi-user"
          class="p-button-text p-button-rounded p-button-lg"
          @click="openUserMenu"
        />
        <Menu ref="menu" :model="items" :popup="true" />
      </div>
    </div>
    <div
      v-if="settings.isLoggedIn"
      class="mobile-menu-drawer fixed mt-7 w-full h-full"
      :class="{
        'open-menu': mobileMenu && !settings.isLoggingOut,
      }"
    >
      <div class="mobile-menu-content w-full h-full">
        <AppMenu :closeMenu="openMobileMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EventBus from "@/AppEventBus";
import AppMenu from "@/AppMenu.vue";
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";
const themes = {
  light: "dark",
  dark: "light",
};
export default defineComponent({
  name: "SplashScreen",
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { settings, user };
  },
  mounted() {
    EventBus.on("closeMenu", this.closeMobileMenu);
  },
  beforeUnmount() {
    EventBus.off("closeMenu", this.closeMobileMenu);
  },
  components: {
    AppMenu: AppMenu,
  },
  data() {
    return {
      mobileMenu: false,
    };
  },
  computed: {
    logo() {
      return `images/logo-${this.settings.theme}.svg`;
    },
    lightSwitcher() {
      const icon = this.settings.theme === "light" ? "moon" : "sun";
      return `pi pi-${icon}`;
    },
    items() {
      const lightSwitcher = {
        label: this.settings.theme === "light" ? "Темная тема" : "Светлая тема",
        icon: `pi pi-${this.settings.theme === "light" ? "moon" : "sun"}`,
        command: this.changeTheme,
      };
      const profileButton = this.settings.isLoggedIn && {
        label: "Профиль",
        icon: "pi pi-user",
        to: "/profile",
      };
      const logInOutButton = this.settings.isLoggedIn
        ? {
            label: "Выйти",
            icon: "pi pi-sign-out",
            command: this.logout,
          }
        : {
            label: "Войти",
            icon: "pi pi-sign-in",
            url: `https://oauth.vk.com/authorize?client_id=8187123&scope=73732&redirect_uri=${process.env.VUE_APP_FRONTEND_URL}/auth&display=page&response_type=token`,
          };
      return [profileButton, lightSwitcher, logInOutButton].filter(Boolean);
    },
  },
  methods: {
    changeTheme() {
      EventBus.emit("theme-change", { theme: themes[this.settings.theme] });
    },
    logout() {
      EventBus.emit("logout");
    },
    openUserMenu(event) {
      this.$refs.menu.toggle(event);
    },
    openMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    closeMobileMenu() {
      this.mobileMenu = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.appbar {
  z-index: 1000;
  line-height: 4rem;
  background: var(--surface-card);
  box-shadow: 0 4px 28px rgba(0, 0, 0, 0.15);

  .mobile-menu-button {
    width: 2rem;
    cursor: pointer;
    transform: rotate(0deg);

    span {
      background-color: var(--text-color);
      content: "";
      height: 2px;
      left: calc(50% - 1.2rem);
      position: absolute;
      top: calc(50% - 1px);
      transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
        transform 0.2s linear;
      width: 2rem;

      &:before,
      &:after {
        background-color: var(--text-color);
        content: "";
        height: 2px;
        position: absolute;
        transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
          transform 0.2s linear;
        width: 2rem;
      }

      &:before {
        top: 0.6rem;
      }

      &:after {
        top: -0.6rem;
      }
    }

    &.open-menu {
      span {
        background-color: transparent;
        transition: background 0.2s ease-out;

        &:before,
        &:after {
          transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
        }

        &:before {
          top: 0;
          transform: rotate3d(0, 0, 1, -45deg);
        }

        &:after {
          top: 0;
          transform: rotate3d(0, 0, 1, 45deg);
        }
      }
    }
  }
}

.mobile-menu {
  max-width: 0%;
  opacity: 0;
  transform: scale(0);
}

.mobile-menu-drawer {
  background: transparent;
  transform: scaleY(0);
  z-index: 1000;
  transform-origin: 0% 0%;
  transition-duration: 400ms;
}

@media screen and (max-width: 800px) {
  .mobile-menu {
    max-width: 100%;
    opacity: 1;
    transform: scale(1);
  }

  .mobile-menu-drawer.open-menu {
    background: var(--surface-border);
    animation: showing 500ms alternate ease-in-out;
    transform: scaleY(1);
  }

  @keyframes showing {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
}
</style>
