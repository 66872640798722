import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplashScreen = _resolveComponent("SplashScreen")!
  const _component_AppTopBar = _resolveComponent("AppTopBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!
  const _component_ScrollTop = _resolveComponent("ScrollTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SplashScreen),
    _createVNode(_component_AppTopBar),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.contentClass)
    }, [
      _createVNode(_component_router_view)
    ], 2),
    _createVNode(_component_AppFooter),
    _createVNode(_component_ScrollTop)
  ], 64))
}