import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

require("dayjs/locale/ru");
import { createApp } from "vue";
import App from "./AppWrapper.vue";
import router from "./router";

import { createPinia } from "pinia";

import { FontAwesomeIcon } from "@/plugins/font-awesome";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmDialog from "primevue/confirmdialog";
import DialogService from "primevue/dialogservice";
import DynamicDialog from "primevue/dynamicdialog";
import Tooltip from "primevue/tooltip";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import MultiSelect from "primevue/multiselect";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ScrollTop from "primevue/scrolltop";
import Skeleton from "primevue/skeleton";
import RadioButton from "primevue/radiobutton";
import Panel from "primevue/panel";
import Tag from "primevue/tag";

import { telegramLoginTemp } from "vue3-telegram-login";

const app = createApp(App);
app.use(router);

// eslint-disable-next-line vue/multi-word-component-names
app.component("fa", FontAwesomeIcon);
app.component("TelegramLogin", telegramLoginTemp);

const pinia = createPinia();
app.use(pinia);

app
  .use(PrimeVue, { ripple: true, inputStyle: "outlined" })
  .use(ToastService)
  .use(ConfirmationService)
  .use(DialogService)
  .directive("tooltip", Tooltip)
  .directive("badge", BadgeDirective)
  .component("Avatar", Avatar)
  .component("Badge", Badge)
  .component("Button", Button)
  .component("Card", Card)
  .component("InputText", InputText)
  .component("Dialog", Dialog)
  .component("Menu", Menu)
  .component("MultiSelect", MultiSelect)
  .component("Toast", Toast)
  .component("ConfirmDialog", ConfirmDialog)
  .component("DynamicDialog", DynamicDialog)
  .component("RadioButton", RadioButton)
  .component("ScrollTop", ScrollTop)
  .component("Skeleton", Skeleton)
  .component("Panel", Panel)
  .component("Tag", Tag);

app.mount("#app");
