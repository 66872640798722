import { defineStore } from "pinia";
import VkService from "@/services/vk.service";
import EventBus from "@/AppEventBus";

interface User {
  id?: string;
  name?: string;
  token?: string;
  settings?: object;
  photo?: string;
  vk: VkService;
  scope: number;
  loading: boolean;
}

export const useUserStore = defineStore("user", {
  state: (): User => ({
    loading: false,
    vk: new VkService(""),
    scope: 0,
  }),

  getters: {
    isLoggedIn: (state) => {
      return (
        !state.loading &&
        state.id &&
        state.name &&
        state.token &&
        !state.settings
      );
    },
  },

  actions: {
    saveToLocalData() {
      if (this.photo) {
        localStorage.setItem("user:photo", this.photo);
      }
    },
    async loadLocalData() {
      this.photo = localStorage.getItem("user:photo") || undefined;
      this.scope = parseInt(localStorage.getItem("user:scope") || "0");
      const token = localStorage.getItem("user:token") || undefined;
      if (token) {
        await this.setToken(token, true);
      }
    },
    async setToken(token: string, needCheck?: boolean) {
      this.loading = true;
      if (needCheck) {
        const testVk = new VkService(token);
        const response = await testVk.getData("account.getAppPermissions");
        if (!response.isSuccess) return;
        this.scope = response.data;
      }
      this.token = token;
      localStorage.setItem("user:token", token);
      localStorage.setItem("user:scope", `${this.scope}`);
      this.vk.setToken(token);
      await this.reloadData();
    },
    async reloadData() {
      this.loading = true;
      const response = await this.vk.getProfile();
      if (!response.isSuccess) return;
      const { data } = response;
      this.id = data.id;
      this.name = `${data.first_name} ${data.last_name}`;
      this.photo = data.photo_200;
      localStorage.setItem("user:photo", data.photo_200);
      this.loading = false;
      EventBus.emit("reload");
    },
    async logout() {
      localStorage.removeItem("user:photo");
      localStorage.removeItem("user:token");
      localStorage.removeItem("user:scope");
      this.token = undefined;
      this.photo = undefined;
      this.id = undefined;
      this.name = undefined;
      this.settings = undefined;
      this.scope = 0;
    },
  },
});
