import fetchJsonp from "fetch-jsonp";

const API_URL = "https://api.vk.com/method/";
const auth = "?v=5.131&access_token=";

export default class VkService {
  token = "";

  constructor(token: string) {
    this.token = token;
  }

  setToken(token: string) {
    this.token = token;
  }

  checkToken() {
    return this.getProfile();
  }

  getProfile() {
    const url = this.getUrl({
      method: "users.get",
      params: { fields: "photo_200" },
    });
    return this.get(url, true);
  }

  getData(method: string, params?: any, onlyFirst?: boolean) {
    const url = this.getUrl({ method, params });
    return this.get(url, onlyFirst);
  }

  private get(
    url: string,
    onlyFirst = false
  ): Promise<{ isSuccess: boolean; data?: any; error?: any }> {
    return new Promise(function (resolve) {
      fetchJsonp(url, {
        timeout: 10000,
        jsonpCallbackFunction: "data",
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          if (json.response) {
            resolve({
              isSuccess: true,
              data: onlyFirst ? json.response[0] : json.response,
            });
          } else if (json.error) {
            resolve({
              isSuccess: false,
              error: json.error,
            });
          } else {
            resolve({
              isSuccess: true,
              data: json,
            });
          }
        })
        .catch(function (ex) {
          resolve({
            isSuccess: false,
            error: ex,
          });
        });
    });
  }

  private getUrl({
    method,
    params = {},
  }: {
    method: string;
    params: any;
  }): string {
    let paramStr = Object.entries(params)
      .map((el) => `${el[0]}=${el[1]}`)
      .join("&");
    if (params && Object.keys(params).length > 0) {
      paramStr = "&" + paramStr;
    }
    return `${API_URL}${method}${auth}${this.token}${paramStr}`;
  }

  public static hasAccess(param: string, scope: number) {
    const params: any = {
      friends: 2,
      photos: 4,
      audio: 8,
      video: 16,
      stories: 64,
      pages: 128,
      status: 1024,
      notes: 2048,
      messages: 4096,
      wall: 8192,
      docs: 131072,
      groups: 262144,
    };
    return (scope & params[param]) > 0;
  }
}
