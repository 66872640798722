import axios from "axios";
import EventBus from "@/AppEventBus";
import authHeader from "./auth-header";
import { deleteCookie, setCookie } from "./cookies";
import * as CryptoJS from "crypto-js";

const API_URL = `${process.env.VUE_APP_BACKEND_URL || ":3300"}/api/`;

class AuthService {
  async login(token: string, id: number) {
    const response = await axios
      .post(API_URL + "auth", {
        token: CryptoJS.AES.encrypt(token, "VKConnect").toString(),
      })
      .then((res) => {
        const bytes = CryptoJS.AES.decrypt(res.data.data, `VKConnect${id}`);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      })
      .catch((e) => {
        EventBus.emit("responseError", e);
        return null;
      });
    if (response) {
      setCookie("vkconnectauth", response.auth, {
        // Выставляем время действия токена неделю
        "max-age": 60 * 60 * 24 * 7,
      });
    }
    return response;
  }

  async refreshToken(id: number) {
    const response = await axios
      .get(API_URL + "auth/refresh", {
        headers: authHeader(),
      })
      .then((res) => {
        const bytes = CryptoJS.AES.decrypt(res.data.data, `VKConnect${id}`);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      })
      .catch((e) => {
        EventBus.emit("responseError", e);
        return null;
      });
    if (response) {
      setCookie("vkconnectauth", response.auth, {
        // Выставляем время действия токена неделю
        "max-age": 60 * 60 * 24 * 7,
      });
    }
    return response;
  }

  logout() {
    deleteCookie("vkconnectauth");
  }
}

export default new AuthService();
