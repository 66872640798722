import VkService from "@/services/vk.service";
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/Auth.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/ProfileView.vue"),
  },
  {
    path: "/stat",
    name: "stat",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/StatsView.vue"),
  },
  {
    path: "/dialogs",
    name: "dialogs",
    meta: {
      requiresAuth: true,
      needScope: "messages",
    },
    component: () => import("@/views/DialogsView.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/SettingsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const settings = useSettingsStore();
  const user = useUserStore();
  if (to.meta.requiresAuth && !settings.isLoggedIn) return "/login";
  const { needScope } = to.meta;
  if (
    needScope &&
    !user.loading &&
    !VkService.hasAccess(<string>needScope, user.scope)
  )
    return "/settings";
  if (to.matched.length === 0) return "/";
});

export default router;
