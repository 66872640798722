<template>
  <div
    class="fixed splash w-full h-full flex justify-content-center align-items-center"
    :class="{
      'light-background': isLightTheme,
      'dark-background': !isLightTheme,
    }"
  >
    <div class="splash-img">
      <img :src="logo" alt="KIOW" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";

export default defineComponent({
  name: "AppSplash",
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { settings, user };
  },
  computed: {
    logo() {
      return `images/kiow-${this.settings.theme}.svg`;
    },
    isLightTheme() {
      return this.settings.theme === "light";
    },
  },
});
</script>

<style lang="scss" scoped>
.light-background {
  background-color: #eff3f8;
}
.dark-background {
  background-color: #040d19;
}
.splash {
  display: block;
  z-index: 2000;
  transform: scale(2);
  animation: fadeout 500ms 1800ms forwards;

  .splash-img {
    display: block;
    width: 15vw;
    animation: fadein 900ms, rubberband 900ms 900ms,
      fadeout 500ms 1800ms 1 forwards;
  }
}
@keyframes fadein {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  99% {
    transform: scale(4);
    opacity: 0;
  }
  100% {
    display: none;
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes rubberband {
  0% {
    transform: scaleX(1);
  }
  40% {
    transform: scaleX(1.12) scaleY(0.75);
  }
  55% {
    transform: scaleX(0.85) scaleY(1);
  }
  65% {
    transform: scaleX(1.09) scaleY(0.85);
  }
  75% {
    transform: scaleX(0.9) scaleY(1);
  }
  90% {
    transform: scaleX(1.05) scaleY(0.95);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}
</style>
