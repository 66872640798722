import { getCookie } from "@/services/cookies";
import { defineStore } from "pinia";

interface Settings {
  party: boolean;
  theme: string;
  isLoggedIn: boolean;
  isLoggingOut: boolean;
}

export const useSettingsStore = defineStore("settings", {
  state: (): Settings => ({
    party: false,
    theme: localStorage.getItem("settings:theme") || "light",
    isLoggedIn: !!getCookie("vkconnectauth"),
    isLoggingOut: false,
  }),

  getters: {},

  actions: {
    loadLocalData() {
      this.$state.theme = localStorage.getItem("settings:theme") || "light";
    },
    setTheme(theme: string) {
      localStorage.setItem("settings:theme", theme);
      this.$state.theme = theme;
    },
  },
});
