
import { defineComponent } from "vue";
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";
export default defineComponent({
  name: "AppFooter",
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { settings, user };
  },
});
