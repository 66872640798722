
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";
import EventBus from "@/AppEventBus";

export default {
  name: "AppMenu",
  props: {
    modal: Boolean,
    closeMenu: Function,
  },
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { settings, user };
  },
  computed: {},
  methods: {
    menuClick(id: number) {
      switch (id) {
        case 0:
          EventBus.emit("route", { route: "/" });
          break;
        case 1:
          EventBus.emit("route", { route: "/stat" });
          break;
        case 2:
          EventBus.emit("route", { route: "/dialogs" });
          break;
        case 99:
          EventBus.emit("route", { route: "/settings" });
          break;
        case 100:
          EventBus.emit("logout");
          break;
      }
      EventBus.emit("closeMenu");
    },
  },
};
