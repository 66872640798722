
import { inject, defineComponent } from "vue";
import InfoDialog from "@/components/InfoDialog.vue";
import VkService from "@/services/vk.service";
import { useSettingsStore } from "@/store/settings";
import { useUserStore } from "@/store/user";

export default defineComponent({
  name: "AddVKDialog",
  setup() {
    const user = useUserStore();
    const settings = useSettingsStore();
    return { settings, user };
  },
  mounted() {
    const dialogRef: any = inject("dialogRef");
    this.saveToken = dialogRef.value.data.saveToken;
    this.closeDialog = dialogRef.value.close;
  },
  data() {
    return {
      type: "partial",
      selectedPermissions: [4096, 8, 16, 2],
      permissions: [
        { label: "Сообщения", value: 4096 },
        { label: "Фотографии", value: 4 },
        { label: "Аудио", value: 8 },
        { label: "Видео", value: 16 },
        { label: "Истории", value: 64 },
        { label: "Документы", value: 131072 },
        { label: "Друзья", value: 2 },
        { label: "Группы", value: 262144 },
      ],
      disabled: (item: { value: number }) => {
        return [4096].includes(item.value);
      },
      token: "",
      parsedToken: "",
      loading: false,
      success: false,
      error: "",
      saveToken: async (token: string) => {
        return { token };
      },
      closeDialog: () => {
        return 1;
      },
    };
  },
  computed: {
    getScope(): number {
      return this.selectedPermissions.reduce(function (sum, elem) {
        return sum + elem;
      }, 65536);
    },
    getAuthUrl(): string {
      const scope = this.getScope;
      return `https://oauth.vk.com/authorize?client_id=2685278&scope=${scope}&redirect_uri=https://oauth.vk.com/blank.html&display=page&response_type=token&revoke=1`;
    },
  },
  methods: {
    clearError() {
      this.success = false;
      this.error = "";
    },
    info() {
      this.$dialog.open(InfoDialog, {
        props: {
          header: "Информация",
          style: {
            width: "20rem",
          },
          modal: true,
          dismissableMask: true,
        },
        data: {
          message:
            "Разрешите приложению доступ к аккаунту, а затем скопируйте целиком адресную строку открывшегося окна (или часть ссылки от 'access_token=' до '&') и вставьте ее в поле ввода.",
        },
      });
    },
    async checkToken({ dontStopLoading }: { dontStopLoading: boolean }) {
      let token = "";
      if (this.token && this.token.indexOf("access_token") !== -1) {
        const params: any = {};
        this.token
          .substring(this.token.indexOf("#"))
          .replace(/^#/, "")
          .split("&")
          .forEach((param) => {
            const parts = param.split("=");
            params[parts[0]] = parts[1];
          });
        token = params["access_token"];
      } else if (this.token) {
        const start =
          this.token.indexOf("=") < 100 ? this.token.indexOf("=") + 1 : 0;
        const end =
          this.token.indexOf("&") !== -1
            ? this.token.indexOf("&")
            : this.token.length;
        token = this.token.substring(start, end);
      }
      if (token.length > 0) {
        this.loading = true;
        const vk = new VkService(token);
        const checkInfo = await vk.checkToken();
        if (checkInfo.isSuccess) {
          if (checkInfo.data.id === this.user.id) {
            this.error = "";
            this.parsedToken = token;
          } else {
            this.error = "Можно использовать токен только от своего аккаунта";
          }
        } else {
          this.error = "Неправильный токен";
        }
        this.loading = dontStopLoading || false;
      } else {
        this.error = "Введите токен доступа в правильном формате";
      }
      this.success = this.error.length === 0;
    },
    async save() {
      if (this.type === "partial") {
        await this.checkToken({ dontStopLoading: true });
        if (!this.success) {
          return;
        }
      }
      const token =
        this.type === "partial" ? this.parsedToken : this.user.token;
      if (token && token.length) {
        const result = await this.saveToken(token);
        if (result) {
          this.closeDialog();
        }
      }
      this.loading = false;
    },
  },
});
